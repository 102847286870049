/*
| Developed by Muzisecur
| Filename: EFEBuilder.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import { ExelysFormBuilder, ExelysFormFieldProps } from '@dirupt/exelys-form'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface EFEBuilderProps {}

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export class EFEBuilder extends ExelysFormBuilder {
	public addPercentageProperty(name: string, props: Omit<ExelysFormFieldProps<'numberField'>, 'name' | 'fieldType'>) {
		return this.addProperty(name, 'numberField', {
			name,
			...props,
			numericFormatProps: {
				allowNegative: false,
				decimalScale: 2,
				decimalSeparator: ',',
				thousandSeparator: ' ',
				suffix: ' %',
				isAllowed: (values) => {
					const { formattedValue, floatValue } = values
					return formattedValue === '' || Number(floatValue ?? 0) <= 100
				},
			},
		})
	}
}
