import { AxiosRequestConfig } from 'axios'
import { mutate } from 'swr'
import { axiosInstance, customAxiosInstance } from '@/services/api/axios-instance'
import { PaginatedResponse } from '@/services/api/common/common.contracts'

export class CrudService<Entity, CreateDto = any, UpdateDto = any> {
	public constructor(
		private endpoint: string,
		private enablePagination = true,
	) {}

	public client = axiosInstance

	public createFindAllUrl(path?: string, params?: object) {
		const urlParams = new URLSearchParams()
		Object.entries(params ?? {}).map(([key, value]) => {
			if (value === undefined) return
			urlParams.append(key, value)
		})
		return urlParams?.size
			? `${this.endpoint}${path ? path : ''}?${urlParams}`
			: `${this.endpoint}${path ? path : ''}`
	}

	public async findOne(id?: string | null, axiosConfig?: AxiosRequestConfig) {
		const url = `${this.endpoint}/${id}`
		return customAxiosInstance<Entity>({
			...axiosConfig,
			url,
			method: 'GET',
		})
	}

	public prepareFindOneHook(id: string | null, axiosConfig?: AxiosRequestConfig) {
		const url = `${this.endpoint}/${id}`
		const fetcher = () => this.findOne(id, axiosConfig)
		return { url: id !== null ? url : null, fetcher }
	}

	public get findAllGlobalKey() {
		return `${this.endpoint}/find-all`
	}
	public async findAllGlobalMutate() {
		await mutate((key) => Array.isArray(key) && key.includes(this.findAllGlobalKey))
	}

	public async mutateOne(id: string) {
		await mutate(`${this.endpoint}/${id}`)
	}

	public async findAll<EnablePaginate = PaginatedResponse<Entity>>(
		params?: object,
		path?: string,
		axiosConfig?: AxiosRequestConfig,
	) {
		const url = this.createFindAllUrl(path, params)
		return customAxiosInstance<EnablePaginate>({ ...axiosConfig, url, method: 'GET' })
	}

	public prepareFindAllHook<EnablePaginate = PaginatedResponse<Entity>>(
		params?: object,
		path?: string,
		axiosConfig?: AxiosRequestConfig,
	) {
		const url = this.createFindAllUrl(path, params)
		const fetcher = () => this.findAll<EnablePaginate>(params, path, axiosConfig)
		return { url: [url, this.findAllGlobalKey], fetcher }
	}

	public async create(data: CreateDto, axiosConfig?: AxiosRequestConfig) {
		return customAxiosInstance<Entity>({
			url: this.endpoint,
			data,
			method: 'POST',
			...axiosConfig,
		})
	}

	public async createFormData(data: CreateDto, axiosConfig?: AxiosRequestConfig) {
		return axiosInstance
			.postForm<Entity>(this.endpoint, {
				...data,
				...axiosConfig,
			})
			.then((res) => res.data)
	}

	public async update(id: string, data: UpdateDto, axiosConfig?: AxiosRequestConfig) {
		return customAxiosInstance<Entity>({
			url: `${this.endpoint}/${id}`,
			data,
			method: 'PATCH',
			...axiosConfig,
		})
	}

	public async delete(id: string, axiosConfig?: AxiosRequestConfig) {
		return customAxiosInstance<Entity>({
			url: `${this.endpoint}/${id}`,
			method: 'DELETE',
			...axiosConfig,
		})
	}
}
