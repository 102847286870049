'use client'

/*
| Developed by Fleet
| Filename: Login.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import * as Yup from 'yup'
import { ExelysForm } from '@dirupt/exelys-form'
import { LinearProgress, Typography } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { useI18n } from '../../../../../locales/client'
import { EFEBuilder } from '@/components/common/exelys-form-extended/EFEBuilder'
import { UrlConfig } from '@/configs/url.config'
import { sessionsEndpoints } from '@/services/api/sessions/sessions.endpoints'
import { Utils } from '@/services/utils'
import { ApiError } from '@/types/errors'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface LoginProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const Login: React.FC<LoginProps> = () => {
	const t = useI18n()
	const router = useRouter()

	// States
	//--------------------------------------------------------------------------
	const [apiError, setApiError] = React.useState<ApiError | null>(null)
	const [isLoading, setIsLoading] = React.useState(false)

	React.useEffect(() => {
		console.log(process.env.NEXT_PUBLIC_API_URL)
	}, [])

	// Memoization
	//--------------------------------------------------------------------------
	const schema = React.useMemo(() => {
		return EFEBuilder.create()
			.addProperty('email', 'customTextField', {
				label: t('common.inputs.email.label'),
				placeholder: t('common.inputs.email.placeholder'),
				validation: Yup.string()
					.email(t('common.inputs.email.invalid'))
					.required(t('common.inputs.email.required')),
				password: false,
			})
			.addProperty('password', 'customTextField', {
				label: t('common.inputs.password.label'),
				placeholder: t('common.inputs.password.placeholder'),
				validation: Yup.string().required(t('common.inputs.password.required')),
				password: true,
			})
			.build()
	}, [t])

	// Methods
	//--------------------------------------------------------------------------
	const handleLogin = async (values: any) => {
		if (isLoading) return
		setIsLoading(true)
		try {
			await sessionsEndpoints.create(values)
			console.log('pushing to ', UrlConfig.home.url)
			router.push(UrlConfig.home.url)
		} catch (error) {
			setApiError(Utils.apiErrorCode(error))
		}
		setIsLoading(false)
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<React.Fragment>
			<Typography variant="h5" textAlign="center">
				{t('auth.login.title')}
			</Typography>
			<ExelysForm
				schema={schema}
				onSubmit={handleLogin}
				footerProps={{
					containerProps: {
						display: 'flex',
						flexDirection: 'column',
					},
					leftComponent: apiError ? (
						<Typography color="error">{t(`errors.${apiError}.title`)}</Typography>
					) : null,
					rightComponent: isLoading ? <LinearProgress sx={{ height: 4 }} /> : null,
					submitProps: {
						children: t('auth.login.submit'),
					},
				}}
			/>

			<Typography>
				{t('auth.login.dontHaveAccount')}
				<Link href={UrlConfig.auth.register.url}>
					<Typography component="span" marginLeft={0.5}>
						{t('auth.login.register')}
					</Typography>
				</Link>
			</Typography>
		</React.Fragment>
	)
}
