export const UrlConfig = {
	auth: {
		login: {
			url: '/auth/login',
			isPublic: true,
		},
		register: {
			url: '/auth/register',
			isPublic: true,
		},
		'send-verification-email': {
			url: '/auth/send-verification-email',
			isPublic: true,
		},
	},
	home: {
		url: '/',
		isPublic: false,
	},
	teachers: {
		url: '/teachers',
		isPublic: false,
	},
	students: {
		url: '/students',
		isPublic: false,
	},
	lessons: {
		url: '/lessons',
		isPublic: false,
	},
	calendar: {
		url: '/calendar',
		isPublic: false,
	},
	payments: {
		url: '/payments',
		isPublic: false,
	},
}

// flatten the object
export const flattenUrlConfig = (config: any, parentKey = '') => {
	const keys = Object.keys(config)
	let result: any = {}

	keys.forEach((key) => {
		const value = config[key]
		const newKey = parentKey ? `${parentKey}.${key}` : key

		if (typeof value === 'object' && 'url' in value) {
			result[newKey] = value
			return
		}
		if (typeof value === 'object') {
			result = { ...result, ...flattenUrlConfig(value, newKey) }
		}
	})

	return result
}

export const publicRoutes = Object.values(flattenUrlConfig(UrlConfig))
	.filter((value: any) => value.isPublic === true)
	.map((value: any) => value.url)

export const protectedRoutes = Object.values(flattenUrlConfig(UrlConfig))
	.filter((value: any) => value.isPublic === false)
	.map((value: any) => value.url)
