import axios, { AxiosRequestConfig } from 'axios'

export const axiosInstance = axios.create({
	baseURL: process.env.NEXT_PUBLIC_API_URL,
	withCredentials: true,
	headers: {
		'Content-Type': 'application/json',
	},
})

/*
|--------------------------------------------------------------------------
| Default response interceptor for error
|--------------------------------------------------------------------------
*/
axiosInstance.interceptors.request.use((config) => {
	const controller = new AbortController()

	if (config.url?.includes('/address/placeholder/')) {
		controller.abort()
	}

	return {
		...config,
		signal: controller.signal,
	}
})

export const customAxiosInstance = async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const { data } = await axiosInstance({
		...config,
		...options,
	})
	return data
}
