import { DateTime, Duration } from 'luxon'
import { AxiosError } from 'axios'
import { useI18n } from '@/locales/client'
import { ApiError } from '@/types/errors'

export class Utils {
	public static apiErrorCode(error: unknown): ApiError {
		let code = ApiError.E_UNKNOWN
		if (error instanceof AxiosError && error.response?.data?.code) {
			code = error.response?.data?.code as ApiError
		}
		return code
	}

	public static arrayToHuman(array: Array<string>, t: ReturnType<typeof useI18n>) {
		if (array.length === 0) return ''
		if (array.length === 1) return array[0]
		// comma except for the last element wth and
		const last = array.pop()
		return `${array.join(', ')} ${t('common.and')} ${last}`
	}

	// public static async copyTextToClipboard(text: string, t: Translate) {
	// 	try {
	// 		await navigator.clipboard.writeText(text)
	// 		toast.success(t('common:common.copiedInClipboard'))
	// 	} catch (error) {
	// 		toast.error(t('common:common.clipboardError'))
	// 	}
	// }

	public static formatFullName(firstName: string, lastName: string) {
		return `${firstName} ${lastName.toUpperCase()}`
	}

	public static capitalize(str: string) {
		const parts = str.split(' ')
		const capitalizedParts = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
		return capitalizedParts.join(' ')
	}

	// public static formatAddress(address?: AddressDto) {
	// 	const addressComponents = []
	//
	// 	if (!address) return ''
	//
	// 	if (address.street) addressComponents.push(address.street)
	// 	if (address.zip && address.city) {
	// 		addressComponents.push(`${address.zip} ${address.city}`)
	// 	}
	// 	if (address.country) addressComponents.push(iso3166CountriesFrench[address.country] ?? address.country)
	//
	// 	return addressComponents.join(', ')
	// }

	// public static registrationDate(date?: string) {
	// 	if (!date) return ''
	// 	return `${DateTime.fromISO(date).toRelative({
	// 		locale: 'fr-FR',
	// 	})} (${DateTime.fromISO(date).setLocale('fr-FR').toLocaleString(DateTime.DATE_FULL)})`
	// }
	//
	// public static lastActivityDate(date?: string) {
	// 	if (!date) return ''
	// 	return DateTime.fromISO(date).toRelative({ locale: 'fr-FR' })
	// }
	//
	// public static serverSideRedirect(context: { req: { url?: string } }, path: string) {
	// 	if (context.req.url?.includes(path)) return
	// 	return {
	// 		redirect: {
	// 			destination: path,
	// 			permanent: false,
	// 		},
	// 	}
	// }
	//
	public static formatDate(date?: string | null, placeholder?: string, format = DateTime.DATE_FULL) {
		if (!date) return placeholder ?? ''
		return DateTime.fromISO(date).toLocaleString(format)
	}

	public static formatDateTime(
		date?: string | null,
		placeholder?: string,
		format = DateTime.DATETIME_MED_WITH_WEEKDAY,
	) {
		if (!date) return placeholder ?? ''
		return DateTime.fromISO(date).toLocaleString(format)
	}

	public static getDiff(start: DateTime, end: DateTime) {
		const durationObject = end.setLocale('fr-FR').diff(start, ['days', 'hour', 'minute']).toObject()
		const filteredDiff = Object.fromEntries(Object.entries(durationObject).filter(([, value]) => value !== 0))
		return Duration.fromObject(filteredDiff).toHuman()
	}

	//
	// public static secondsToMinutesAndSeconds(d: number) {
	// 	const duration = Duration.fromObject({ seconds: d })
	// 	return duration.toFormat("m' min' s's")
	// }
	//
	// static get DATETIME_FULL() {
	// 	return 'dd LLL yyyy à HH:mm'
	// }
	//
	// public static getDiff(start: DateTime, end: DateTime) {
	// 	const durationObject = end.setLocale('fr-FR').diff(start, ['days', 'hour', 'minute']).toObject()
	// 	const filteredDiff = Object.fromEntries(Object.entries(durationObject).filter(([, value]) => value !== 0))
	// 	return Duration.fromObject(filteredDiff, { locale: 'fr-FR' }).toHuman()
	// }
	//
	// static downloadFile = async (
	// 	url: string,
	// 	filename: string,
	// 	setProgress?: React.Dispatch<React.SetStateAction<number | undefined>>,
	// ) => {
	// 	const response = await axiosInstance.get(url, {
	// 		responseType: 'blob',
	// 		onDownloadProgress: (progressEvent) => {
	// 			setProgress?.(Math.round(Number(progressEvent?.progress ?? 0) * 100))
	// 		},
	// 	})
	// 	const a = document.createElement('a')
	// 	a.href = URL.createObjectURL(response.data)
	// 	a.setAttribute('download', filename)
	// 	a.click()
	// }
	//
	// public static humanFileSize(bytes: number, si = false, dp = 1) {
	// 	const thresh = si ? 1000 : 1024
	//
	// 	if (Math.abs(bytes) < thresh) {
	// 		return bytes + ' B'
	// 	}
	//
	// 	const units = si
	// 		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	// 		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	// 	let u = -1
	// 	const r = 10 ** dp
	//
	// 	do {
	// 		bytes /= thresh
	// 		++u
	// 	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)
	//
	// 	return bytes.toFixed(dp) + ' ' + units[u]
	// }
	//
	// public static megaToBytes(megaBytes: number) {
	// 	return megaBytes * 1024 * 1024
	// }
}
